import React from "react";
import { Carousel } from "react-bootstrap";
import { graphql, useStaticQuery, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

// Components

const Hero = () => {
  const { herodata } = useStaticQuery(
    graphql`
      query {
        herodata: file(relativePath: { eq: "settings/hero.md" }) {
          childMarkdownRemark {
            frontmatter {
              herotext
              herosubtext
              heroslider {
                imagealt
                image {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                      transformOptions: { fit: COVER, cropFocus: CENTER }
                    )
                  }
                }
              }
              heroyoutubeenable
              heroyoutubeid
              heroyoutubetitle
              herobtn1txt
              herobtn1lnk
              herobtn2txt
              herobtn2lnk
              herobtn3txt
              herobtn3lnk
            }
          }
        }
      }
    `
  );
  const frontmatter = herodata.childMarkdownRemark.frontmatter
  const heroyoutubeenable = frontmatter.heroyoutubeenable

  return (
    <>
    <section className="max-w-screen-2xl mx-auto">
      <Carousel className={heroyoutubeenable? "block lg:hidden" : "block"} 
        controls={true} indicators={false} fade={false} interval={5000}>
        {frontmatter.heroslider.map((heroimage, i) => {
            return (
            <Carousel.Item key={i} >
                <div className="relative h-425 sm:h-375 md:h-465 lg:h-555 xl:h-625 grid">
                  {/* {frontmatter.heroyoutubeenable === true && ( */}
                    <GatsbyImage image={heroimage.image.childImageSharp.gatsbyImageData} alt={heroimage.imagealt}
                      className={heroyoutubeenable? "imageWrapper row-start-1 col-start-1 block lg:hidden" : "imageWrapper row-start-1 col-start-1 block"}
                      layout="fullWidth"
                      aspectratio={3 / 1}
                    />
                  {/* )} */}
                  {/* <div className="row-start-1 col-start-1 relative grid place-content-end grid-cols-1" >
                    <div className="font-cursive py-4 px-8 text-center sm:mb-16 video:mb-20">
                        <h1 className="text-white text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-normal">{frontmatter.herotext}</h1>
                        <p className="text-white text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl font-normal">{frontmatter.herosubtext}</p>
                    </div>
                  </div> */}
                </div>
            </Carousel.Item>
        )})}
      </Carousel>
      <div className="flex lg:hidden justify-center content-center w-full absolute top-72 sm:top-56 md:top-72">
        <div className="flex flex-col container mx-auto justify-center items-center relative z-10 font-cursive">
          <h1 className="text-white text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-normal">{frontmatter.herotext}</h1>
          <p className="text-white text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl font-normal">{frontmatter.herosubtext}</p>
        </div>
      </div>
      {heroyoutubeenable === true && (
        <div className="hidden lg:grid relative h-468 sm:h-422 md:h-full md:max-h-768">
          <div className="videoWrapper bg-white w-full h-full row-start-1 col-start-1 -mt-40">
            <iframe
              src={`https://www.youtube-nocookie.com/embed/`+frontmatter.heroyoutubeid+`?version=3&enablejsapi=1&html5=1&hd=1&wmode=opaque&showinfo=0&rel=0&controls=0&autoplay=1&mute=1&loop=1&playlist=`+frontmatter.heroyoutubeid}
              title={frontmatter.heroyoutubetitle}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
            />
          </div>
          <div className="row-start-1 col-start-1 relative grid place-content-end grid-cols-1" >
            <div className="font-cursive py-4 px-8 text-center sm:mb-16 video:mb-20">
                <h1 className="text-white text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-normal">{frontmatter.herotext}</h1>
                <p className="text-white text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl font-normal">{frontmatter.herosubtext}</p>
            </div>
          </div>
        </div>
      )}
      <div className="hidden lg:flex justify-center content-center container mx-auto w-full -mt-6">
          <div className="flex justify-center items-center relative z-10">
              <div className="hidden lg:block border-gray-300 border">
                  <Link to={frontmatter.herobtn1lnk} className="inline-block py-2 px-6 bg-white font-cursive tracking-wider">{frontmatter.herobtn1txt}</Link> 
              </div>
              <div className="hidden lg:block border-gray-300 border-t border-b">
              <Link to={frontmatter.herobtn2lnk} className="inline-block py-2 px-6 bg-white font-cursive tracking-wider">{frontmatter.herobtn2txt}</Link> 
              </div>
              <div className="hidden lg:block border-template-accent border">
              <Link to={frontmatter.herobtn3lnk} className="inline-block py-2 px-6 bg-template-accent text-white font-cursive tracking-wider">{frontmatter.herobtn3txt}</Link>
              </div>
          </div>
      </div>
      </section>
    </>
  );
};

export default Hero;
