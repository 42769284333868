import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Components
import IconStar from '../images/IconStar'

const ReviewsWidget = () => {
    const { reviews } = useStaticQuery(
        graphql`
          query {
            reviews: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(reviews)/.*[.]md$/"}}) {
                edges {
                  node {
                    frontmatter {
                      title
                      reviewtext
                      reviewname
                      reviewstars
                    }
                  }
                }
              }
          }
        `
      );
    return (
        <>
            <div className="container mx-auto px-2">
                <div className="flex justify-around mx-4">
                    <div className="flex flex-wrap -m-4">
                    {reviews.edges.map((edge, index) => {
                        const frontmatter = edge.node.frontmatter
                        var stars = [];
                        for (var i = 0; i < frontmatter.reviewstars; i++) {
                            stars.push(<IconStar width="20px" height="20px" className="fill-current" key={i}></IconStar>);
                        }
                        return (
                        <div className="lg:w-1/3 lg:mb-0 mb-6 p-4" key={index}>
                            <div className="h-full text-center">
                            <div className="flex justify-center mb-2 text-template-accent">
                                {stars}
                            </div>
                            <h2 className="font-display font-bold text-lg md:text-xl lg:text-2xl pb-2">{frontmatter.title}</h2>
                            <p className="font-light text-gray-600 text-sm md:text-base mb-2 leading-relaxed font-cursive">{frontmatter.reviewtext}</p>
                            <span className="inline-block h-1 w-10 rounded bg-template-accent mt-2 mb-2"></span>
                            <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">{frontmatter.reviewname}</h2>
                            </div>
                        </div>
                        )})}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReviewsWidget