import React from 'react'
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo'
import Hero from '../components/Hero'
import Reviews from "../components/Reviews"

// Assets
import lavendar from '../images/lavendar-bg.jpg'

const IndexPage = () => {

    const { settingshome } =
      useStaticQuery(graphql`
        query {
          settingshome: file(relativePath: { eq: "settings/homepage.md" }) {
            childMarkdownRemark {
              frontmatter {
                widgetone {
                  description
                  title
                }
                widgettwo {
                  description
                  featuredimage {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                        transformOptions: { fit: COVER, cropFocus: CENTER }
                      )
                    }
                  }
                  title
                }
                widgetthree {
                  sectionone {
                    featuredimage {
                      publicURL
                    }
                    featurelink {
                      link
                      type
                    }
                    title
                  }
                  sectiontwo {
                    featuredimage {
                      publicURL
                    }
                    featurelink {
                      link
                      type
                    }
                    title
                  }
                }
                widgetfour {
                  description
                  featuredimage {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                        transformOptions: { fit: COVER, cropFocus: CENTER }
                      )
                    }
                  }
                  title
                }
                widgetfive {
                  description
                  featuredimage {
                    publicURL
                  }
                  featurelink1 {
                    link
                    type
                  }
                  linktext1
                  featurelink2 {
                    link
                    type
                  }
                  linktext2
                  title
                }
              }
            }
          }
        }
      `);

    const settingw1 = settingshome.childMarkdownRemark.frontmatter.widgetone
    const settingw2 = settingshome.childMarkdownRemark.frontmatter.widgettwo
    const settingw3 = settingshome.childMarkdownRemark.frontmatter.widgetthree
    const settingw4 = settingshome.childMarkdownRemark.frontmatter.widgetfour
    const settingw5 = settingshome.childMarkdownRemark.frontmatter.widgetfive

    return (
        <>
        <Layout>
            <Seo />
            <Hero />
            <div className="container mx-auto">
                {/* Widget One */}
                <section className="max-w-2xl mx-auto text-center pt-8">
                    <div className="w-24 h-2 bg-template-accent mb-4 mx-auto"></div>
                    <h2 className="font-display font-bold text-2xl md:text-3xl lg:text-4xl mb-6 font-cursive px-6">{settingw1.title}</h2>
                    <p className="font-light text-gray-600 leading-relaxed font-cursive text-sm md:text-base lg:text-lg px-6">{settingw1.description}</p>
                </section>
              </div>
              <div className="relative bottom-0 w-full h-96 opacity-20 -mt-96 lavendar-bg" style={{ zIndex: "-1", backgroundImage: `url(${lavendar})`, backgroundRepeat: 'repeat-x', backgroundPosition: 'bottom'}}></div>
              <div className="container mx-auto">
                {/* Widget Two */}
                <section className="grid grid-cols-1 md:grid-cols-2 my-8">
                    <div className="bg-white p-0 flex justify-end items-center md:row-start-1 md:col-start-2">
                    <GatsbyImage image={settingw2.featuredimage.childImageSharp.gatsbyImageData} alt={settingw2.title}
                        className="w-full h-full object-cover"
                        layout="fullWidth"
                        aspectratio={3 / 1}
                    />
                    </div>
                    <div className="bg-gray-100 p-12 flex justify-start items-center md:row-start-1 md:col-start-1">
                        <div className="max-w-md">
                            <div className="w-24 h-2 bg-template-accent mb-4"></div>
                            <h2 className="font-display font-bold text-2xl md:text-3xl lg:text-4xl mb-6 font-cursive">{settingw2.title}</h2>
                            <p className="font-light text-gray-600 text-sm md:text-base lg:text-lg mb-6 leading-relaxed font-cursive">{settingw2.description}</p>
                        </div>
                    </div>
                </section>
                {/* Widget Three */}
                <section className="flex flex-wrap bg-black my-8">
                    {!settingw3.sectionone.featurelink[0].link.startsWith("http")?
                    <Link to={settingw3.sectionone.featurelink[0].link} 
                        style={{backgroundImage: `url(${settingw3.sectionone.featuredimage.publicURL})`, backgroundSize: "cover", backgroundPosition: "center"}}
                        className="bg-black relative w-full md:w-auto md:flex-1 flex items-center justify-center h-72 md:h-96 font-heading text-white uppercase tracking-widest opacity-70 hover:opacity-90 transition duration-500 ease-in-out">
                        <div className="relative z-10 font-cursive text-2xl bg-black px-2 bg-opacity-70">{settingw3.sectionone.title}</div>
                    </Link>
                    :
                    <a href={settingw3.sectionone.featurelink[0].link} target="_blank" rel="noreferrer"
                        style={{backgroundImage: `url(${settingw3.sectionone.featuredimage.publicURL})`, backgroundSize: "cover", backgroundPosition: "center"}}
                        className="bg-black relative w-full md:w-auto md:flex-1 flex items-center justify-center h-72 md:h-96 font-heading text-white uppercase tracking-widest opacity-60 hover:opacity-90 transition duration-500 ease-in-out">
                        <div className="relative z-10 font-cursive text-2xl bg-black px-2 bg-opacity-70">{settingw3.sectionone.title}</div>
                    </a>
                    }
                    {!settingw3.sectiontwo.featurelink[0].link.startsWith("http")?
                    <Link to={settingw3.sectiontwo.featurelink[0].link} 
                        style={{backgroundImage: `url(${settingw3.sectiontwo.featuredimage.publicURL})`, backgroundSize: "cover", backgroundPosition: "center"}}
                        className="bg-black relative w-full md:w-auto md:flex-1 flex items-center justify-center h-72 md:h-96 font-heading text-white uppercase tracking-widest opacity-60 hover:opacity-90 transition duration-500 ease-in-out">
                        <div className="relative z-10 font-cursive text-2xl bg-black px-2 bg-opacity-70">{settingw3.sectiontwo.title}</div>
                    </Link>
                    :
                    <a href={settingw3.sectiontwo.featurelink[0].link} target="_blank" rel="noreferrer"
                        style={{backgroundImage: `url(${settingw3.sectiontwo.featuredimage.publicURL})`, backgroundSize: "cover", backgroundPosition: "center"}}
                        className="bg-black relative w-full md:w-auto md:flex-1 flex items-center justify-center h-72 md:h-96 font-heading text-white uppercase tracking-widest opacity-60 hover:opacity-90 transition duration-500 ease-in-out">
                        <div className="relative z-10 font-cursive text-2xl bg-black px-2 bg-opacity-70">{settingw3.sectiontwo.title}</div>
                    </a>
                    }
                </section>
                {/* Widget Four */}
                <section className="grid grid-cols-1 md:grid-cols-2 my-8">
                    <div className="bg-white p-0 flex justify-end items-center">
                    <GatsbyImage image={settingw4.featuredimage.childImageSharp.gatsbyImageData} alt={settingw4.title}
                        className="w-full h-full object-cover"
                        layout="fullWidth"
                        aspectratio={3 / 1}
                    />
                    </div>
                    <div className="bg-gray-100 p-12 flex justify-start items-center">
                        <div className="max-w-md">
                            <div className="w-24 h-2 bg-template-accent mb-4"></div>
                            <h2 className="font-display font-bold text-2xl md:text-3xl lg:text-4xl mb-6 font-cursive">{settingw4.title}</h2>
                            <p className="font-light text-gray-600 text-sm md:text-base lg:text-lg mb-6 leading-relaxed font-cursive">{settingw4.description}</p>
                        </div>
                    </div>
                </section>
                {/* Widget Five */}
                <div className="relative w-full py-12 px-12 font-cursive my-8 grid" style={{backgroundImage: `url(${settingw5.featuredimage.publicURL})`, backgroundSize: "cover", backgroundPosition: "center"}}>
                    <div className="relative z-10 text-center py-12 md:py-24 ">
                        <h2 className="text-white text-center text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-display mb-6 bg-black bg-opacity-60 px-8 py-3">{settingw5.title}</h2>
                        <p className="text-white mb-10 text-lg md:text-2xl bg-black bg-opacity-60 px-8 py-3">{settingw5.description}</p>
                        <div className="pb-2">
                        {!settingw5.featurelink1[0].link.startsWith("http")?
                        <Link to={settingw5.featurelink1[0].link} className="inline-block bg-template-accent text-black uppercase text-base tracking-widest font-heading px-6 py-2">{settingw5.linktext1}</Link>
                        :
                        <a href={settingw5.featurelink1[0].link} target="_blank" rel="noreferrer" className="inline-block bg-template-accent text-black uppercase text-base tracking-widest font-heading px-6 py-2">{settingw5.linktext1}</a>
                        }
                        </div>
                        <div className="pt-2">
                        {!settingw5.featurelink2[0].link.startsWith("http")?
                        <Link to={settingw5.featurelink2[0].link} className="inline-block bg-template-accent text-black uppercase text-base tracking-widest font-heading px-6 py-2">{settingw5.linktext2}</Link>
                        :
                        <a href={settingw5.featurelink2[0].link} target="_blank" rel="noreferrer" className="inline-block bg-template-accent text-black uppercase text-base tracking-widest font-heading px-6 py-2">{settingw5.linktext2}</a>
                        }
                        </div>
                    </div>
                </div>
                {/* Reviews */}
                <Reviews />
            </div>
        </Layout>
        </>
    )
}

export default IndexPage